import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getPropertyId,
  getReservationId,
  getUserId,
} from "src/app/common/utils/localStorageUtils";
import CheckoutForm from "../../components/CheckoutForm";
import { MessageButton } from "../../components/MessageButton";
import { showPageLoader } from "../Middleware/actionCreators";

import {
  getHoldAmountByPropertyService,
  getReservationByIdService,
  getStripePublicTokenService,
  getUserUploadedDocumentService,
} from "./service";
import "./styles.scss";
import { Spinner } from "../../components/Spinner";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { createOrUpdateWebCheckInStatusService, updatePreCheckInStatusService } from "../../common/services/AppServices";
import { HOLD_PAYMENT_SERVICES } from "./constants";
const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
    },
  ],
};

const HoldPayment = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [stripePromise, setStripePromise] = useState(null);
  const [holdAmount, setHoldAmount] = useState("250.00");

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "auto",
    });
    initiate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initiate = async () => {
    try {
      const reservation = await getReservationDetails();
      const holdPaymentId = reservation.hold_transaction_id;

      if (holdPaymentId) {
        await confirmPreCheckIn();
      } else {
        await getStripePublicToken();
        await getHoldAmountByProperty();
        await getUserUploadedDocument();
      }

      await sleep(6000);
    } catch (error) {
      redirectToErrorScreen(error);
    } finally {
      setIsLoading(false);
    }
  };

  const confirmPreCheckIn = async () => {
    try {
      const statusUpdate = await createOrUpdateWebCheckInStatusService("WEB_CHECKIN");
      const reservationId = await getReservationId();
      const preCheckInStatus = await updatePreCheckInStatusService(
        { reservationId: reservationId, preCheckInStatus: true }
      );

      console.info('statusUpdate', statusUpdate)

      if (statusUpdate && preCheckInStatus) {
        props.history.push("/check-in-success");
      }
    } catch (error) {
      console.error("Error", error);
      // redirectToErrorScreen(error);
    }
  };

  const getReservationDetails = async () => {
    try {
      const reservationDetails = await getReservationByIdService(
        HOLD_PAYMENT_SERVICES.getReservationByReservationId.apiEndpoint,
        { reservationId: await getReservationId() }
      );

      return JSON.parse(reservationDetails);
    } catch (error) {
      redirectToErrorScreen(error);
    }
  };

  function sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  const getUserUploadedDocument = async () => {
    const { apiEndpoint } = HOLD_PAYMENT_SERVICES.getUserUploadedDocuments;
    try {
      const reservationId = await getReservationId();
      const userId = await getUserId();
      const response = await getUserUploadedDocumentService(apiEndpoint, {
        reservationId,
        userId,
      });

      if (
        (response && response.length > 0,
          response[0].hasOwnProperty("identity_doc_s3_key"))
      ) {
        if (
          !response[0].identity_doc_s3_key ||
          response[0].identity_doc_s3_key === ""
        ) {
          props.history.push("/upload-document");
        }
      }
    } catch (error) {
      redirectToErrorScreen(error);
    }
  };

  const redirectToErrorScreen = (error) => {
    console.info("Error", error);

    props.history.push("/error");
  };

  const getStripePublicToken = async () => {
    const propertyId = await getPropertyId();
    try {
      const response = await getStripePublicTokenService(
        HOLD_PAYMENT_SERVICES.getStripePublicToken.apiEndpoint,
        propertyId
      );

      const stripeToken = loadStripe(response.stripePublicToken);

      setStripePromise(stripeToken);
    } catch (error) {
      redirectToErrorScreen(error);
    }
  };

  const getHoldAmountByProperty = async () => {
    const propertyId = await getPropertyId();
    try {
      const holdAmount = await getHoldAmountByPropertyService(
        HOLD_PAYMENT_SERVICES.getHoldAmountByProperty.apiEndpoint,
        propertyId
      );

      setHoldAmount(holdAmount.property.toFixed(2).toString());
    } catch (error) {
      redirectToErrorScreen(error);
    }
  };

  return (
    isLoading ? <Spinner/> :
    <div className="checkin-screen aligning-middle">
      <div className="checking-details">
        <div className="checkin-hold">
          <div
            className="check-titles"
            style={{ padding: "5px", fontSize: "16px" }}
          >
            Enter your Credit Card Information
          </div>

          <div
            className="check-titles"
            style={{ padding: "5px", fontSize: "8px", textAlign: "center" }}
          >
            A temporary hold of ${holdAmount} will be placed on your card for
            incidentals
          </div>

          <div style={{ padding: "15px 15px 0px 15px" }}>
            <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
              <CheckoutForm history={props.history} />
            </Elements>
          </div>
        </div>
      </div>
      <div style={{ paddingTop: "10px" }}>
        <MessageButton />
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  showPageLoader,
};

HoldPayment.propTypes = {
  showPageLoader: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(HoldPayment);
