import AxiosBuilder from "src/app/common/services/AxiosBuilder";
import { getJwtToken } from "src/app/common/utils/sessionUtils";
import { getPhoneNumber, getReservationId } from "src/app/common/utils/localStorageUtils";

export const getReservationDetailsService = async (apiEndpoint, params) => {
	const token = await getJwtToken();
	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint)
			.withAuth(token)
			.withParams({ reservationId: params.reservationId, propertyId: params.propertyId })
			.GET()
			.then((response) => {
				let r = response?.data;
				if (response && response.data && typeof response.data === "string") {
					r = JSON.parse(response.data);
				}
				resolve(r);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getStripePublicTokenService = async (apiEndpoint, propertyId) => {
	const token = await getJwtToken();
	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint, {
			data: JSON.stringify({
				propertyId
			})
		})
			.withAuth(token)
			.POST()
			.then((response) => {
				resolve(JSON.parse(response.data));
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getHoldAmountByPropertyService = async (apiEndpoint, propertyId) => {
	const token = await getJwtToken();
	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint, {
			data: JSON.stringify({
				propertyId
			})
		})
			.withAuth(token)
			.POST()
			.then((response) => {
				resolve(JSON.parse(response.data));
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const stripeHoldService = async (stripeId, amount) => {
	const token = await getJwtToken();
	const apiEndpoint = "stripe/hold";
	const reservationId = await getReservationId();
	const phone = await getPhoneNumber();
	const phoneNumber = "+" + atob(phone).replace("+", "");
	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint, {
			data: JSON.stringify({
				stripeId,
				amount,
				reservationId,
				phoneNumber
			})
		})
			.withAuth(token)
			.POST()
			.then((response) => {
				let r = response?.data;
				if (response && response.data && typeof response.data === "string") {
					r = JSON.parse(response.data);
				}
				resolve(r);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getUserUploadedDocumentService = async (apiEndpoint, params) => {
	const token = await getJwtToken();
	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint)
			.withAuth(token)
			.withParams(params)
			.GET()
			.then((response) => {
				let r = response?.data;
				if (response && response.data && typeof response.data === "string") {
					r = JSON.parse(response.data);
				}
				resolve(r);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getExtraChargesItemsService = async (apiEndpoint, propertyId) => {
	const token = await getJwtToken();

	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint, {
			data: JSON.stringify({
				propertyId: propertyId
			})
		})
			.withAuth(token)
			.POST()
			.then((response) => {
				let r = response?.data;
				if (response && response.data && typeof response.data === "string") {
					r = JSON.parse(response.data);
				}
				resolve(r);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const createExtraChargeService = async (apiEndpoint, params) => {
	const token = await getJwtToken();

	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint, {
			data: JSON.stringify({
				propertyId: params.propertyId,
				pmsPropertyId: params.pmsPropertyId,
				reservationId: params.reservationId,
				description: params.description,
				amount: params.amount,
				quantity: params.quantity,
				paymentId: params.paymentId,
				extraFieldValue: params.extraFieldValue
					? params.extraFieldValue.toUpperCase()
					: null
			})
		})
			.withAuth(token)
			.POST()
			.then((response) => {
				let r = response?.data;
				if (response && response.data && typeof response.data === "string") {
					r = JSON.parse(response.data);
				}
				resolve(r);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const sendNotificationService = async (apiEndpoint, params) => {
	const token = await getJwtToken();

	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint, {
			data: JSON.stringify({
				propertyId: params.propertyId,
				pmsPropertyId: params.pmsPropertyId,
				reservationId: params.reservationId,
				extraChargeItemId: params.extraChargeItemId
			})
		})
			.withAuth(token)
			.POST()
			.then((response) => {
				let r = response?.data;
				if (response && response.data && typeof response.data === "string") {
					r = JSON.parse(response.data);
				}
				resolve(r);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const chargeService = async (apiEndpoint, params) => {
	const token = await getJwtToken();

	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint, {
			data: JSON.stringify({
				propertyId: params.propertyId,
				paymentMethodId: params.paymentMethodId,
				description: params.description,
				amount: params.amount
			})
		})
			.withAuth(token)
			.POST()
			.then((response) => {
				let r = response?.data;
				if (response && response.data && typeof response.data === "string") {
					r = JSON.parse(response.data);
				}
				resolve(r);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const refundService = async (apiEndpoint, params) => {
	const token = await getJwtToken();

	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint, {
			data: JSON.stringify({
				propertyId: params.propertyId,
				paymentId: params.paymentId,
				amount: params.amount
			})
		})
			.withAuth(token)
			.POST()
			.then((response) => {
				let r = response?.data;
				if (response && response.data && typeof response.data === "string") {
					r = JSON.parse(response.data);
				}
				resolve(r);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getReservationDataService = async (apiEndpoint, params) => {
	const token = await getJwtToken();

	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint)
			.withAuth(token)
			.withParams(params)
			.GET()
			.then((response) => {
				let r = response?.data;
				if (response && response.data && typeof response.data === "string") {
					r = JSON.parse(response.data);
				}
				resolve(r);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const updatePreCheckInService = async (apiEndpoint, params) => {
	const token = await getJwtToken();

	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint, {
			data: JSON.stringify({
				reservationId: params.reservationId,
				preCheckInStatus: params.preCheckInStatus
			})
		})
			.withAuth(token)
			.POST()
			.then((response) => {
				let r = response?.data;
				if (response && response.data && typeof response.data === "string") {
					r = JSON.parse(response.data);
				}
				resolve(r);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
