import AxiosBuilder from "src/app/common/services/AxiosBuilder";
import {
	getPhoneNumber,
	getPropertyId,
	getReservationId
} from "src/app/common/utils/localStorageUtils";
import { getJwtToken } from "src/app/common/utils/sessionUtils";

export const getReservationByIdService = async (apiEndpoint, params) => {
	const token = await getJwtToken();
	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint)
			.withAuth(token)
			.withParams({ reservationId: params.reservationId })
			.GET()
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getReservationDetailsService = async (apiEndpoint, params) => {
	const token = await getJwtToken();
	const propertyId = await getPropertyId();
	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint)
			.withAuth(token)
			.withParams({ reservationId: params.reservationId, propertyId })
			.GET()
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getStripePublicTokenService = async (apiEndpoint, propertyId) => {
	const token = await getJwtToken();
	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint, {
			data: JSON.stringify({
				propertyId
			})
		})
			.withAuth(token)
			.POST()
			.then((response) => {
				let r = response?.data;
				if (response && response.data) {
					r = JSON.parse(response.data);
				}
				resolve(r);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
export const getHoldAmountByPropertyService = async (apiEndpoint, propertyId) => {
	const token = await getJwtToken();
	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint, {
			data: JSON.stringify({
				propertyId
			})
		})
			.withAuth(token)
			.POST()
			.then((response) => {
				resolve(JSON.parse(response.data));
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const stripeHoldService = async (stripeId, amount) => {
	const token = await getJwtToken();
	const apiEndpoint = "stripe/hold";
	const reservationId = await getReservationId();
	const phone = await getPhoneNumber();
	const phoneNumber = "+" + atob(phone).replace("+", "");
	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint, {
			data: JSON.stringify({
				stripeId,
				amount,
				reservationId,
				phoneNumber
			})
		})
			.withAuth(token)
			.POST()
			.then((response) => {
				let r = response?.data;
				if (response && response.data && typeof response.data === "string") {
					r = JSON.parse(response.data);
				}
				resolve(r);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getUserUploadedDocumentService = async (apiEndpoint, params) => {
	const token = await getJwtToken();
	return new Promise(function (resolve, reject) {
		new AxiosBuilder(apiEndpoint)
			.withAuth(token)
			.withParams(params)
			.GET()
			.then((response) => {
				let r = response?.data;
				if (response && response.data && typeof response.data === "string") {
					r = JSON.parse(response.data);
				}
				resolve(r);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
