import React, { useEffect, useState } from "react";
import moment from "moment";
import "./styles.scss";
import {
	getExtraChargesItemsService,
	getReservationDetailsService,
	createExtraChargeService,
	chargeService,
	refundService,
	getReservationDataService,
	updatePreCheckInService,
	sendNotificationService
} from "./service";
import { CHECK_IN_SERVICES } from "./constants";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { showPageLoader } from "../Middleware/actionCreators";
import { createOrUpdateWebCheckInStatusService } from "../../common/services/AppServices";
import { WEB_CHECKIN_STATUS_TYPES } from "../../common/constants/AppConstants";
import { getPropertyId, getReservationId } from "src/app/common/utils/localStorageUtils";
import { MessageButton } from "../../components/MessageButton";
import { AppCheckBox } from "../../components/AppCheckBox";
const Field = ({
	id,
	type,
	placeholder,
	required,
	autoComplete,
	value,
	onChange,
	helpText = ""
}) => (
	<div>
		<div className="FormGroupServices">
			<input
				className="FormRowInputServices"
				id={id}
				type={type}
				placeholder={placeholder}
				required={required}
				autoComplete={autoComplete}
				value={value}
				onChange={onChange}
			/>
		</div>
		<small className="FormRowHelpTextServices">{helpText}</small>
	</div>
);

const ExtraServices = (props) => {
	const [reservationData, setReservationData] = useState(null);
	const [selectedExtraCharges, setChecked] = useState([]);
	const [checkList, setChecklist] = useState([]);
	const [total, setTotal] = useState(0);
	const [reservationDays, setReservationDays] = useState(0);
	const [isAgreementAccepted, setIsAgreementAccepted] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [error, setError] = useState(null);

	useEffect(() => {
		window.scroll({
			top: 0,
			behavior: "auto"
		});

		initiate();
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const initiate = async () => {
		props.showPageLoader(true);
		try {
			const propertyId = await getPropertyId();
			const reservationId = await getReservationId();
			const reservationDetailsResponse = await getReservationDetails();
			await getExtraChargesItems(reservationDetailsResponse.reservationDays);
			const { apiEndpoint } = CHECK_IN_SERVICES.getReservationData;
			const params = {
				reservationId: parseInt(reservationId),
				propertyId: parseInt(propertyId)
			};

			const response = await getReservationDataService(apiEndpoint, params);

			if (response) {
				setReservationData(response);
			}
		} catch (error) {
			redirectToErrorScreen(error);
		} finally {
			props.showPageLoader(false);
		}
	};

	const getReservationDetails = async () => {
		try {
			const reservationDetails = await getReservationDetailsService(
				CHECK_IN_SERVICES.getUserReservationDetails.apiEndpoint,
				{
					reservationId: await getReservationId(),
					propertyId: await getPropertyId()
				}
			);

			const date1 = moment(reservationDetails.checkin_date);
			const date2 = moment(reservationDetails.checkout_date);
			const diff = date2.diff(date1, "days");
			setReservationDays(diff);

			reservationDetails.reservationDays = diff;
			return reservationDetails;
		} catch (error) {
			redirectToErrorScreen(error);
		}
	};

	const redirectToErrorScreen = () => {
		props.history.push("/error");
	};

	const getExtraChargesItems = async (days) => {
		const propertyId = await getPropertyId();
		try {
			const extraChargesItems = await getExtraChargesItemsService(
				CHECK_IN_SERVICES.getExtraChargesItems.apiEndpoint,
				propertyId
			);
			let availableExtraChargesItems = [];
			extraChargesItems.forEach((item) => {
				if (item.pre_checkin && item.active) {
					item.isChecked = false;
					availableExtraChargesItems.push(item);

					if (item.required) {
						if (item.charge_reservation_days) {
							item.quantity = days;
							item.total = parseFloat(item.amount * days);
						} else {
							item.quantity = 1;
							item.total = parseFloat(item.amount);
						}
						selectedExtraCharges.push(item);
					}
				}
			});

			const totalAmount = calculateTotal(selectedExtraCharges);
			setTotal(totalAmount);
			console.log("availableExtraChargesItems", availableExtraChargesItems);
			setChecklist(availableExtraChargesItems);
		} catch (error) {
			console.log(error);
		}
	};

	const calculateTotal = (list) => {
		try {
			let total = 0;
			list.forEach((item) => {
				total = total + parseFloat(item.total);
			});
			return total.toFixed(2);
		} catch (error) {
			console.log(error);
		}
	};

	const handleCheck = (i, event) => {
		let updatedList = [...selectedExtraCharges];

		if (event.target.checked) {
			if (checkList[i].charge_reservation_days) {
				checkList[i].quantity = reservationDays;
				checkList[i].total = parseFloat(checkList[i].amount * reservationDays);
			} else {
				checkList[i].quantity = 1;
				checkList[i].total = parseFloat(checkList[i].amount);
			}

			updatedList = [...selectedExtraCharges, checkList[i]];
			const totalAmount = calculateTotal(updatedList);
			setTotal(totalAmount);
		} else {
			const index = selectedExtraCharges.findIndex(
				(obj) => obj.description === checkList[i].description
			);
			updatedList.splice(index, 1);
			const totalAmount = calculateTotal(updatedList);
			setTotal(totalAmount);
		}
		setChecked(updatedList);
	};

	const handleExtraFieldChange = (i, event) => {
		const index = selectedExtraCharges.findIndex(
			(obj) => obj.description === checkList[i].description
		);
		if (index !== -1) {
			selectedExtraCharges[index].extra_field_value = event.target.value;
		}
	};

	const isChecked = (description) => {
		const index = selectedExtraCharges.findIndex((obj) => obj.description === description);
		if (index !== -1) {
			return true;
		} else {
			return false;
		}
	};

	const handleConfirmClick = async () => {
		props.showPageLoader(true);
		setProcessing(true);
		try {
			if (total > 0) {
				await createExtraCharge();
			} else {
				await confirmPreCheckIn();
			}
		} finally {
			setProcessing(false);
			props.showPageLoader(false);
		}
	};

	const updateStatus = async () => {
		props.showPageLoader(true);

		try {
			const response = await createOrUpdateWebCheckInStatusService(
				WEB_CHECKIN_STATUS_TYPES.WEB_CHECKIN
			);
			return response;
		} catch (error) {
			props.showPageLoader(false);
			redirectToErrorScreen(error);
		} finally {
			props.showPageLoader(false);
		}
	};

	const updatePreCheckInStatus = async () => {
		props.showPageLoader(true);

		try {
			const reservationId = await getReservationId();

			const { apiEndpoint } = CHECK_IN_SERVICES.updatePreCheckIn;
			const response = await updatePreCheckInService(apiEndpoint, {
				reservationId: reservationId,
				preCheckInStatus: true
			});
			return response;
		} catch (error) {
			props.showPageLoader(false);
			redirectToErrorScreen(error);
		} finally {
			props.showPageLoader(false);
		}
	};

	const confirmPreCheckIn = async () => {
		try {
			const statusUpdate = await updateStatus();
			const preCheckInStatus = await updatePreCheckInStatus();
			if (statusUpdate && preCheckInStatus) {
				props.history.push("/check-in-success");
			}
		} catch (error) {
			setError(true);
			setErrorMessage("Unable to complete pre check-in");
		} finally {
			props.showPageLoader(false);
		}
	};

	const createExtraCharge = async () => {
		try {
			setError(false);
			props.showPageLoader(true);
			const { apiEndpoint } = CHECK_IN_SERVICES.createExtraCharge;
			const { apiEndpoint: sendNotificationEndpoint } = CHECK_IN_SERVICES.sendNotification;
			const propertyId = await getPropertyId();
			const reservationId = await getReservationId();
			const { pms_property_id } = reservationData;

			let failedPaymentsCount = 0;
			const successPaymentExtraCharges = [];

			props.showPageLoader(true);

			for (const extraCharge of selectedExtraCharges) {
				try {
					const paymentResult = await charge(extraCharge.description, extraCharge.total);
					if (paymentResult.status === "failed") {
						failedPaymentsCount++;
					} else {
						successPaymentExtraCharges.push({
							...extraCharge,
							paymentId: paymentResult.id
						});
					}
				} catch (error) {
					console.error(error);
				}
			}

			if (failedPaymentsCount > 0) {
				for (const successPayment of successPaymentExtraCharges) {
					await refund(propertyId, successPayment.paymentId, successPayment.amount);
				}
			} else {
				if (successPaymentExtraCharges.length !== selectedExtraCharges.length) {
					for (const successPayment of successPaymentExtraCharges) {
						await refund(propertyId, successPayment.paymentId, successPayment.amount);
					}
				} else {
					for (const successPayment of successPaymentExtraCharges) {
						await createExtraChargeService(apiEndpoint, {
							propertyId: propertyId,
							pmsPropertyId: pms_property_id,
							reservationId: reservationId,
							description: successPayment.description,
							amount: successPayment.total,
							quantity: successPayment.quantity,
							paymentId: successPayment.paymentId,
							extraFieldValue: successPayment.extra_field_value || null
						});

						await sendNotificationService(sendNotificationEndpoint, {
							propertyId: propertyId,
							pmsPropertyId: pms_property_id,
							reservationId: reservationId,
							extraChargeItemId: successPayment.id
						});
					}

					await confirmPreCheckIn();
				}
			}

			if (
				failedPaymentsCount > 0 ||
				successPaymentExtraCharges.length !== selectedExtraCharges.length
			) {
				setError(true);
				setErrorMessage("Unable to complete the charge. Please, try again.");
			}
		} catch (error) {
			setError(true);
			setErrorMessage("Unable to complete the charge");
		} finally {
			props.showPageLoader(false);
		}
	};

	const charge = async (description, amount) => {
		props.showPageLoader(true);
		const { apiEndpoint } = CHECK_IN_SERVICES.charge;
		const { property_id, payment_method_id } = reservationData;

		try {
			const paymentResult = await chargeService(apiEndpoint, {
				propertyId: property_id,
				paymentMethodId: payment_method_id,
				description: description,
				amount: amount
			});

			return paymentResult;
		} catch (error) {
			setError(true);
			setErrorMessage("Unable to complete the charge");
		}
	};

	const refund = async (propertyId, paymentId, amount) => {
		const { apiEndpoint } = CHECK_IN_SERVICES.refund;
		try {
			await refundService(apiEndpoint, {
				propertyId: propertyId,
				paymentId: paymentId,
				amount: amount
			});
		} catch (error) {
			console.error("Error to refund extra charges:", paymentId);
		}
	};

	return (
		<div className="checkin-screen aligning-middle">
			{props.showPageLoader && reservationData && checkList && (
				<>
					<h2>Extras Services</h2>
					<span className="extra-services-subtitle">
						Include extra services to your reservation
					</span>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center"
						}}
					>
						<div style={{ paddingTop: "20px" }}>
							{reservationData &&
								checkList &&
								checkList.map((item, index) => (
									<div key={item.id}>
										<div
											style={{
												display: "flex",
												flexDirection: "row",
												alignItems: "center",
												justifyContent: "start",
												paddingTop: "10px"
											}}
										>
											{!item.required ? (
												<label className="checkbox-container">
													<input
														value={item}
														type="checkbox"
														onChange={(e) => handleCheck(index, e)}
													/>
													<span className="checkmark"></span>
												</label>
											) : (
												<label className="checkbox-container">
													<input
														value={item}
														type="checkbox"
														checked={true}
														readOnly={true}
													/>
													<span className="checkmark"></span>
												</label>
											)}

											<div>
												{item.description + " "}
												{item.charge_reservation_days
													? "($" +
													  item.amount * reservationDays +
													  " for the whole stay)"
													: "($" + item.amount + ")"}
											</div>
										</div>
										{isChecked(item.description) && item.extra_field ? (
											<div
												style={{
													paddingTop: "10px",
													paddingBottom: "10px"
												}}
											>
												<Field
													label={item.extra_field_description}
													id="name"
													type="text"
													placeholder={item.extra_field_description}
													required
													autoComplete="name"
													onChange={(e) => {
														handleExtraFieldChange(index, e);
													}}
													helpText={
														item.description.includes("Parking")
															? 'If you are renting a car and do not yet have this information, please enter "RENTAL"'
															: ""
													}
												/>
											</div>
										) : null}
									</div>
								))}
							<div style={{ paddingTop: "20px" }}>{`Total: $${total}`}</div>

							<div
								style={{
									display: "flex",
									justifyContent: "flex-start",
									alignItems: "center",
									paddingTop: "20px"
								}}
							>
								<AppCheckBox
									isChecked={isAgreementAccepted}
									toggleChecked={setIsAgreementAccepted}
								/>
								<div
									style={{
										display: "flex"
									}}
								>
									I agree and accept this charge
								</div>
							</div>
						</div>

						{error && (
							<div
								style={{
									display: "flex",
									justifyContent: "start",
									alignItems: "center",
									paddingTop: "25px",
									fontSize: "14px",
									fontWeight: 500,
									color: "red"
								}}
							>
								{errorMessage}
							</div>
						)}

						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								width: "75.7%"
							}}
						>
							<button
								className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
								type="submit"
								onClick={handleConfirmClick}
								disabled={processing || !isAgreementAccepted}
							>
								{processing ? "Processing..." : "Complete Pre Check-In"}
							</button>
						</div>
					</div>

					<div style={{ paddingTop: "10px" }}>
						<MessageButton />
					</div>
				</>
			)}
		</div>
	);
};

const mapDispatchToProps = {
	showPageLoader
};

ExtraServices.propTypes = {
	showPageLoader: PropTypes.func
};

export default connect(null, mapDispatchToProps)(ExtraServices);
