import React from "react";
import "./styles.scss";

const NotAvailable = () => {
  return (
    <React.Fragment>
      <div className="content">
        <h2>
          Please complete the pre-check link contained in new email with a reservation code that begins with LAX1US.
        </h2>
        <h4>
          If you have any questions, please call Hollywood Hills Hotel at +1 (323) 999-1539 and we will be happy to assist.
        </h4>
      </div>
    </React.Fragment>
  );
};

export default NotAvailable;
