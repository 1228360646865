export const HOLD_PAYMENT_SERVICES = {
  getStripePublicToken: {
    key: "stripe",
    apiEndpoint: "stripe/getStripePublicToken",
  },
  getHoldAmountByProperty: {
    key: "stripe",
    apiEndpoint: "stripe/getHoldAmountByProperty",
  },
  getUserUploadedDocuments: {
    apiEndpoint: "reservation/getGuestUserReservationIdentityDocuments",
  },
  getHasHold: {
    apiEndpoint: "stripe/getHasHold",
  },
  getReservationByReservationId: {
    apiEndpoint: "getReservationByReservationId",
  },
};
